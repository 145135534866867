const sha256 = async (message: string) => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);
  // hash the message. Only getRandomValues() is available on insecure contexts
  if (window.isSecureContext) {
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    // convert bytes to hex string
    const hashHex = hashArray.map((b) => ("00" + b.toString(16)).slice(-2)).join("");
    return hashHex;
  } else {
    return message;
  }
};

const dateString2Local = (dateString: string) => {
  const date = new Date(dateString);

  if (isNaN(date.valueOf())) return "";

  return date.toLocaleDateString("fr");
};

export default { sha256, dateString2Local };
