import React from "react";
import logo from "../../assets/logo.png";

const Logo: React.FC = () => {
  return (
    <div className="flex-col-center w-100">
      <img src={logo} />
    </div>
  );
};

export default Logo;
