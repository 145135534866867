import React, { useState } from "react";
import PasswordForm from "./PasswordForm";

interface Props {
  hashedPwd: string;
}

const LockerWrapper: React.FC<Props> = (props) => {
  const { children, hashedPwd } = props;

  const [isLocked, setIsLocked] = useState(true);

  const onFinish = async () => {
    setIsLocked(false);
  };

  if (isLocked) return <PasswordForm onFinish={onFinish} hashedPwd={hashedPwd} />;

  return <>{children}</>;
};

export default LockerWrapper;
