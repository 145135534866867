import React from "react";
import { Button, Form, Input } from "antd";
import utils from "../../utils";
import { FormContainer } from "./PasswordForm.style";

interface Props {
  hashedPwd: string;
  onFinish: (formValues: any) => void;
}

const formFields = {
  password: { label: "Mot de passe", name: "password" },
};

const PasswordForm: React.FC<Props> = (props) => {
  const { hashedPwd, onFinish } = props;

  return (
    <FormContainer className="password-form-container rounded">
      <Form
        name="basic"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label={formFields.password.label}
          name={formFields.password.name}
          validateFirst={true}
          rules={[
            { required: true, message: "Un mot de passe est nécessaire" },
            {
              validateTrigger: "submit",
              message: "Mot de passe incorrect.",
              validator: async (_, value) => {
                const hashedTry = await utils.sha256(value);

                if (hashedTry === hashedPwd) return;

                throw new Error("Password mismatch");
              },
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10, span: 4 }}>
          <Button type="primary" htmlType="submit">
            Valider
          </Button>
        </Form.Item>
      </Form>
    </FormContainer>
  );
};

export default PasswordForm;
