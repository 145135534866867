import { Spin } from "antd";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Logo } from "./components";

const ROUTES = {
  CUSTOMER: {
    path: "customer/:applicationId",
    Component: React.lazy(() => import("./pages/PublicRanking/PublicRanking")),
  },
  ADMIN: {
    path: "admin",
    Component: React.lazy(() => import("./pages/AdminRanking/AdminRanking")),
  },
  DEFAULT: {
    path: "*",
    Component: Logo,
  },
};

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Spin />}>
        <Routes>
          {Object.values(ROUTES).map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
